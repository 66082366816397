document.querySelectorAll('.accordion-header').forEach(button => {
  button.addEventListener('click', () => {
    const accordionContent = button.nextElementSibling;
    const toggleIcon = button.querySelector('.accordion-toggle');

    if (accordionContent.style.maxHeight) {
      accordionContent.style.maxHeight = null;
      toggleIcon.classList.remove('is-open');
    } else {
      accordionContent.style.maxHeight = accordionContent.scrollHeight + "px";
      toggleIcon.classList.add('is-open');
    }
  });
});
